<template>
  <div class="video-detail-page common-page">
    <Nav />
    <div class="top">
      <video :src="detail.url" alt="" controls/>
    </div>
    <div class="common-card-wrap card-wrap">
      <h2>{{ detail.name }}</h2>
      <p class="text" v-html="detail.detail"></p>
    </div>
  </div>
</template>
<script>
import request from '../../utils/request';
import Nav from "../../component/nav.vue";
export default {
  components: {
    Nav
  },
  data() {
    return {
      detail: {}
    };
  },
  methods: {
    // 获取新闻列表数据
    fetchInfo(id) {
      request.get('/video/'+id) // 假设接口地址为 /news
        .then(response => {
          this.detail = response; // 将接口返回的数据赋值给newsList
          document.title = this.detail.name;
        })
        .catch(error => {
          console.error('Error fetching news:', error);
        });
    }
  },
  created() {
    const id = this.$route.query.id;
    // 在组件创建时调用fetchNewsList方法获取新闻列表数据
    this.fetchInfo(id);
  }
};
</script>
<style lang="less" scoped>
.video-detail-page {
  .top {
    padding-top: 80px;
    video {
      display: block;
      width: 100%;
      height: 211px;
      object-fit: cover;
    }
  }
  .card-wrap {
    box-sizing: border-box;
    min-height: calc(100vh - 211px);
    h2 {
      margin-bottom: 8px;
      color: #212027;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 33.6px */
    }
    .text {
      color: #505054;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.28px;
    }
  }
}
</style>
